.mainComponent{
    width: 52vw;
    margin: 0 auto;
    padding-top: 2.9375vw;
    margin-bottom: 5.5vw;
}
.selfDriveBenefitsItems{
    padding: 2.5vw 2.25vw;
}
.selfDriveBenefitsItems>div{
    margin-bottom: 1.8vw;
}
.subHeading{
    font-size: 1.25vw;
    font-weight: 800;
    font-family: 'interBold';
    color: #3E2A1A;
}
.description{
    font-size: 1.25vw;
    font-weight: 400;
    font-family: 'inter';
    color: #3E2A1A;
}
.MainHeading{
    color:#3E2A1A;
    font-family: 'interBold';
    font-size:2vw;
    font-weight:800;
    text-align:center;
}
.Heading{
    color:#3E2A1A;
    font-family: 'interBold';
    font-size:1.5vw;
    font-weight:800;
    text-align:center;
    padding-bottom: 10px;
}
.team-container {
   
    background-color: #f3f4f6;
    padding: 3rem 1rem;
    text-align: center;
  }
  
  .team-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #1f2937;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .team-card {
    background-color: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .team-card:hover {
    transform: scale(1.05);
  }
  
  .team-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 4px solid #d1d5db;
    margin-bottom: 1rem;
  }
  
  .team-name {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
  }
  
  .team-role {
    font-size: 1rem;
    color: #6b7280;
  }
  
@media only screen and (max-width: 768px) {
    .mainComponent{
        width: 90vw;
        margin: 0 auto;
        padding-top: 6vw;
        margin-bottom: 6vw;
    }
    .selfDriveBenefitsItems{
        padding: 5vw 0vw;
    }
    .selfDriveBenefitsItems>div{
        margin-bottom: 5vw;
    }
    .subHeading{
        font-size: 3.8vw;
        font-weight: 800;
        font-family: 'interBold';
        color: #3E2A1A;
    }
    .description{
        font-size: 3.5vw;
        font-weight: 400;
        font-family: 'inter';
        color: #3E2A1A;
    } 
    .MainHeading{
        color:#3E2A1A;
        font-family: 'interBold';
        font-size:6vw;
        font-weight:800;
        text-align:center;
    }
    .Heading{
        color:#3E2A1A;
        font-family: 'interBold';
        font-size:4vw;
        font-weight:800;
        text-align:center;
    }
}