.headerMainContainer {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
}

.headerOverlayDiv {
    height: 6.5625vw;
    width: 100%;
    background: linear-gradient(360deg, rgba(0, 0, 0, 0) 10.65%, rgba(0, 0, 0, 0.25) 33.57%, rgba(0, 0, 0, 0.6) 91.01%);
}

.headerInnerContainer {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    padding-top: 1vw;
    display: flex;
    justify-content: space-between;
}

.headerInnerContainer>div {
    display: flex;
    gap: 2.6875vw;
}

.headerItemMainDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.25vw;
    margin-top: 0.9375vw;
}

.headerItemDiv {
    font-size: 1vw;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
}
.registerBtn{
    background-color: #FAC809;
    border: 0.125vw solid rgb(62, 42, 26);
    padding: 0.3125vw;
    border-radius: 2.5vw;
    color: rgb(62, 42, 26);
    width: 6.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
}

@media only screen and (max-width: 768px) {
    .headerInnerContainer {
        height: 100%;
        width: 100%;
        margin: 0 auto;
        padding: 1vw;
        padding-left: 3vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #dropdown-basic {
        background: transparent;
        border: none;
    }

    #dropdown-basic::after {
        display: none !important;
    }
    .dropdown-item{
    padding: 2vw 5vw;
    }
    .headerOverlayDiv {
        height: 18vw;
        width: 100%;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0) 10.65%, rgba(0, 0, 0, 0.25) 33.57%, rgba(0, 0, 0, 0.6) 91.01%);
    }
}


.aboutUsContainer {
    position: relative;
    cursor: pointer;
}

.aboutUsDropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 0.5vw;
    width: 12vw;
    padding: 0.5vw 0;
    display: none;
    flex-direction: column;
}

.aboutUsContainer:hover .aboutUsDropdown {
    display: flex;
}

.dropdownItem {
    color: white;
    padding: 0.5vw 1vw;
    font-size: 0.9vw;
    cursor: pointer;
}

.dropdownItem:hover {
    background: rgba(255, 255, 255, 0.2);
}


.registerButton {
    background-color: #FAC809;
    border: 0.125vw solid rgb(62, 42, 26);
    padding: 0.3125vw;
    border-radius: 2.5vw;
    color: rgb(62, 42, 26);
    width: 6.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1vw;
    cursor: pointer;
    font-weight: bold;
}

.registerButton:hover {
    background-color: #ffdb4d;
}
.registerButtonContainer
{
    padding: 0.1875vw;
    border-radius: 2.5vw;
    background: rgb(250, 200, 9);
    margin-top: -0.6vw;
    margin-left: 2vw;
}